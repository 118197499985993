import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {getCookie} from "cookies-next";
import * as Sentry from "@sentry/nextjs";
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';

export default function DialogDuplicateVersion ({masterProductUUID,toggleDuplicateVersion,typePlugin,completeDuplicate,
                                                    versionProductUUID,triggerWarning}) {


    const toggleDuplicateVersionInside = useCallback((): void => {

        if(showDuplicateDialog){
            changeDuplicateShow(false);
        }else{
            changeDuplicateShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDuplicateVersion && toggleDuplicateVersion(toggleDuplicateVersionInside);
    }, [toggleDuplicateVersion, toggleDuplicateVersionInside]);

    const [canClick, setCanClick] = useState(true);
    const duplicateProduct = () => {
        setCanClick(false);
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+masterProductUUID+"/versions/"+versionProductUUID, {
            method: 'POST',

            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){

                if(data.message !== undefined){
                    Sentry.captureException("products/"+masterProductUUID+"/versions/"+versionProductUUID+": "+ data.message + " : " + getCookie("userMail"));
                    if(data.error_code){
                        triggerWarning(data.error_code);
                    }else{
                        triggerWarning(data.message);
                    }
                    changeDuplicateShow(false);
                }

                if(data.message === undefined){
                    if(!typePlugin){
                        window.open("/editor/"+data.data.uuid, "_self");
                    } else {

                        changeDuplicateShow(false);
                        completeDuplicate(data.data.uuid);
                    }
                    //    setVersionIsLocked(data.data.locked.locked);


                }
                setCanClick(true);

            });
    };


    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeDuplicateShow(false);
        }
    }
    // };
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
    const [showDuplicateDialogTransition, setShowDuplicateDialogTransition] = useState(false);
    const changeDuplicateShow = (variable) => {

        if (variable) {
            setShowDuplicateDialog(true);
            setTimeout(function() {
                setShowDuplicateDialogTransition(true);
            }, 2);
        } else {

            setShowDuplicateDialogTransition(false);
            setTimeout(function() {
                setShowDuplicateDialog(false);
            }, 200);
        }
    };


    return (
        <>
            <ModalsWrapper
                id={"duplicateVersion"}
                className={`  ${showDuplicateDialogTransition ? 'showTransition' : 'hideTransition'} ${!showDuplicateDialog ? 'hiddeComplete' : ''}`}
              data-closemodal={true}
                onClick={(e)=> closeModalOutsideClick(e)}>


                <ModalWindow id={'duplicateDialogVersion'} style={window.innerWidth < 900 ?{ minHeight: 'unset', height: '230px' } : { minHeight: 'unset', height: '190px' }}
                             className={'center modal_dialog_small'}>
                    <div className={'modal-header'}>
                        <h4> <FormattedMessage {...messages.modal_new_version_title}></FormattedMessage></h4>

                    </div>


                    <div className={'modal-content shareProduct'} style={{ display: 'block' }}>
                        <span><FormattedMessage {...messages.modal_new_version_description}></FormattedMessage></span>

                        <div className={'modal-buttons'} style={{    width: "90%"}}>

                            <GlobalButtonFinal
                                className={'borderBlue btn-circle'}
                                onClick={() => changeDuplicateShow(false)}>
                                <FormattedMessage {...messages.modal_new_version_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>

                            <GlobalButtonFinal  className={'backgroundBlue btn-circle '}
                                                data-uuid={'productID'}
                                                onClick={ canClick ? () => duplicateProduct(masterProductUUID, versionProductUUID)  : null}>
                                <FontAwesomeIcon icon={faPlus} />  <FormattedMessage {...messages.modal_new_version_button_duplicate_version}></FormattedMessage>
                            </GlobalButtonFinal>
                        </div>

                    </div>
                </ModalWindow>


            </ModalsWrapper>






        </>
    )
}