import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../GlobalStyles';
import { getCookie } from 'cookies-next';
import { faCheck, faBuilding } from '@fortawesome/pro-regular-svg-icons';
import * as Sentry from '@sentry/nextjs';
import ObaleroFileUploader from '../ObaleroFileUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';
import { LangContext } from '../../../../src/ConnectedIntlProvider';
import DialogAlert from './DialogAlert';

export default function DialogCreateNewTeam({ toggleCreateNewTeam, loadTeamsForSelect, switchTeam }) {
    const toggleCreateNewTeamInside = useCallback((): void => {
        if (modalNewTeamActive) {
            changeModalNewTeamActive(false);
        } else {
            changeModalNewTeamActive(true);
        }
    }, []);
    useEffect(() => {
        toggleCreateNewTeam && toggleCreateNewTeam(toggleCreateNewTeamInside);
    }, [toggleCreateNewTeam, toggleCreateNewTeamInside]);

    const setInputTeamLogoFunction = file => {
        setInputTeamLogo(file);
    };

    const [inputTeamLogo, setInputTeamLogo] = useState();

    const [newTeamName, setNewTeamName] = useState('');

    const handleNewTeamName = function (e: React.FormEvent<HTMLInputElement>) {
        setNewTeamName(e.currentTarget.value);
    };

    const { language, setLanguage } = useContext(LangContext);
    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {

    };

    const AddNewTeam = () => {
        if (file !== null) {
            uploadNewTexture();
        } else {
            gotResponseUploadTextureAPI2('success', null, 'teamLogo');
        }
    };

    const uploadNewTexture = () => {
        if(newTeamName.length >3){
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'uploads/prepare-form', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + getCookie('userT'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function (data) {
                    if (data.message !== undefined) {
                        Sentry.captureException('uploadNewTexture DialogCreateNewTeam uploads/prepare-form' + ': ' + data.message + ' : ' + getCookie('userMail'));
                    }

                    let formData = new FormData();

                    Object.keys(data.formInputs).forEach(key => {
                        formData.append(key, data.formInputs[key]);
                    });

                    formData.append('file', file);

                    fetch(data.formAttributes.action, {
                        method: data.formAttributes.method,
                        // headers: {
                        //     'Content-type': data.formAttributes.enctype
                        // },
                        body: formData,
                    })
                        .then(function (dataUpload) {
                            gotResponseUploadTextureAPI2('success', '/' + data.formInputs.key, 'teamLogo');
                        })
                        .catch(function (e) {
                            Sentry.captureException(data.formAttributes.action + ': ' + data.message + ' : ' + getCookie('userMail'));
                        });

                    //uploadEditorTexture(inputTeamLogo, data.formInputs.key, gotResponseUploadTextureAPI2, "teamLogo");
                });
        } else {
            if(language === "cs"){
                triggerWarning("Krátké jméno");
            } else {
                triggerWarning("Short name");
            }

        }

    };
    const gotResponseUploadTextureAPI2 = (response, key, type) => {
        if(newTeamName.length >3){
        if (response === 'success') {
            //updateOptionAPI(type, key);
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'teams', {
                method: 'POST',
                body: JSON.stringify({
                    name: newTeamName,
                    logo_path: key,
                }),
                headers: {
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + getCookie('userT'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function (data) {
                    if (data.message !== undefined) {
                        Sentry.captureException('gotResponseUploadTextureAPI2 DialogCreateNewTeam teams' + ': ' + data.message + ' : ' + getCookie('userMail'));
                        if(data.error_code){
                            triggerWarning(data.error_code);
                        }else{
                            triggerWarning(data.message);
                        }
                    }

                    if (data.message === undefined) {
                        setNewTeamName("");
                        setFile(null);
                        changeModalNewTeamActive(false);
                        if (switchTeam) {
                            switchTeam(data.data.uuid);
                        } else {
                            if (loadTeamsForSelect) {
                                loadTeamsForSelect();
                            }
                        }
                    }
                });
        }
        } else {
            if(language === "cs"){
                triggerWarning("Krátké jméno");
            } else {
                triggerWarning("Short name");
            }

        }
    };
    const [modalNewTeamActive, changeModalNewTeamActive] = useState(false);

    const closeModal = () => {

        setFile(null);
        changeModalNewTeamActive(false);
    };
    const closeModalOutsideClick = e => {
        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeModalNewTeamActive(false);
        }
    };
    const [file, setFile] = useState(null);

    return (
        <>
            <ModalsWrapper className={modalNewTeamActive ? '' : 'hideModal'} data-closemodal={true} onClick={e => closeModalOutsideClick(e)}
            style={{zIndex: "9999999999999999999"}}>
                <ModalWindow id={'newTeamModal'} className={` modal_dialog_small ${modalNewTeamActive ? '' : 'hideModal'} `}>
                    <div className={'modal-header'}>
                        <h4 style={{ textAlign: 'center', fontSize: '22px !important', paddingBottom: '30px' }}>
                            <FormattedMessage {...messages.modal_create_new_team_title}></FormattedMessage>
                        </h4>
                    </div>
                    <br />

                    <div className={'modal-content  editGroups'} style={{ display: 'block' }}>



                        <FormattedMessage {...messages.modal_create_new_team_input_placeholder}>
                            {(msg) => (
                                <input type={'text'} placeholder={msg} value={newTeamName} className={'newTeamNameInput'}
                                       onChange={handleNewTeamName} style={{ width: '100%' }} />
                            )}
                        </FormattedMessage>


                        <div style={{ width: '25%', display: 'inline-block',     position: "relative", top: "30px" }} className={"addNewMember"} >

                            {file ? (
                                <div className={'addNewMember'} style={{ position: 'relative', top: '50px' }}>
                                    <h5>
                                        <FormattedMessage {...messages.modal_create_new_team_upload_icon}></FormattedMessage>
                                    </h5>
                                    <img style={{ border: '1px solid lightgray', maxWidth: '100%' }}
                                         src={URL.createObjectURL(file)} />
                                </div>

                            ) : (
                                <>
                                    <h5>
                                        <FormattedMessage {...messages.modal_create_new_team_upload_icon}></FormattedMessage>
                                    </h5>
                                    <div >
                                        <img style={{ opacity: '50%', maxWidth: '100%' }} src={'/images/logo.svg'} />

                                        {/*<FontAwesomeIcon icon={faBuilding} />*/}
                                    </div>
                                </>

                            )}
                        </div>

                        <div style={{ width: '71.6%', display: 'inline-block' }}>
                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>
                        <br />
                    </div>
                    <div className={'modal-buttons'}>
                        <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModal()}>
                            <FormattedMessage {...messages.modal_create_new_team_button_cancel}></FormattedMessage>
                        </GlobalButtonFinal>
                        <GlobalButtonFinal className={'backgroundBlue btn-circle addNewTeamButton'} onClick={AddNewTeam}>
                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '8px' }} />
                            <FormattedMessage {...messages.modal_create_new_team_button_save}></FormattedMessage>
                        </GlobalButtonFinal>
                    </div>
                </ModalWindow>

                <DialogAlert
                    toggleAlert={ (toggleAlert: () => void) => {
                        handleTriggerWarning = toggleAlert;
                    } }
                    alertType={"warning"}
                    message={warningMessage}
                />

            </ModalsWrapper>
        </>
    );
}
